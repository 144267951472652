<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Alejandro Orozco Marulanda                                    ###### -->
<!-- ###### @date: Octubre 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <v-form ref="itemForm2" class="pr-2 pl-3">

    <v-card-title class="mb-0">
      Información del Evaluado 
    </v-card-title>

    <section class="d-flex">
      <section class="d-grid mr-2" :style="{ width: '50%' }">
        <!--Campo para mostrar el número de documento (no es editable)-->
        <v-text-field 
          v-model="crearItem.colaborador.numeroDocumento" label="Número de Documento" 
          readonly dense outlined color="primary" :hide-details="false">
        </v-text-field>
        <!--Campo para mostrar el nombre del evaluado (no es editable)-->
        <v-text-field 
          v-model="crearItem.nombreCompleto" label="Nombre Completo"  
          readonly dense outlined color="primary" :hide-details="false">
        </v-text-field>
        <!--Campo para mostrar el cargo del evaluado-->
        <FilterCustomized
        v-model="crearItem.cargoAct.idCargo" :style="{ width: '100%' }" :readonly="!permisosHR || crearItem.listaEvaluacion.eliminado"
        :items="listaEdicion.cargoAct" item-value="id" item-text="name" label="Cargo"
        @update:search-input= "(value) =>llenarLista('cargo',listaEdicion.cargoAct,value,'idCargo','nombre',false)"
        :rules="rules.required" :append-icon="computedAppendIcon" hide-details="false"/>
      </section>

      <section class="d-grid" :style="{ width: '50%' }">
        <!--Campo para seleccionar el proceso)-->
        <FilterCustomized
        v-model="crearItem.grupoProceso.proceso.idProceso" class="mb-7" :readonly="!permisosHR || crearItem.listaEvaluacion.eliminado"
        :items="listaEdicion.proceso" item-value="id" item-text="name" label="Proceso"
        @update:search-input= "(value) =>llenarLista('proceso',listaEdicion.proceso,value,'idProceso','nombre',false)"
        :rules="rules.required" :hide-details="false" :append-icon="computedAppendIcon"
        />
        <!--Campo para seleccionar el grupo proceso)-->
        <FilterCustomized
        v-model="crearItem.grupoProceso.idGrupoProceso" :readonly="!permisosHR || crearItem.listaEvaluacion.eliminado"
        :items="listaEdicion.grupoProceso" item-value="id" item-text="name" label="Área" class="mb-7"
        @update:search-input= "(value) =>llenarLista('grupo-proceso',listaEdicion.grupoProceso,value,'idGrupoProceso','nombre',false)"
        :rules="rules.required" :hide-details="false" :append-icon="computedAppendIcon"
        />
        <!--Campo para seleccionar la ubicacion-->
        <FilterCustomized
        v-model="crearItem.ubicacionColaborador.idUbicacionColaborador" :readonly="!permisosHR || crearItem.listaEvaluacion.eliminado"
        :items="listaEdicion.ubicacionColaborador" item-value="id" item-text="name" label="Ubicación" 
        @update:search-input= "(value) =>llenarLista('ubicacion',listaEdicion.ubicacionColaborador,value,'idUbicacionColaborador','nombre',false)"
        :rules="rules.required" :hide-details="false" :append-icon="computedAppendIcon"
        />
      </section>

    </section>
    
    <v-card-title class="mb-0">
      Evaluador
    </v-card-title>

    <section class="d-flex mb-3" >
        <!--Campo que muestra el nombre del evaluador-->
      <v-text-field 
        v-model="crearItem.nombreEvaluador" label="Nombre Completo" :style="{ minWidth: '40%' }"
        readonly dense outlined color="primary" class="mr-2"
        :hide-details="false"
        >
      </v-text-field>
        <!--Campo cargo del evaluador-->
      <FilterCustomized
      v-model="crearItem.cargoEvaluador.idCargo" :readonly="!permisosHR || crearItem.listaEvaluacion.eliminado"
      :items="listaEdicion.cargoEvaluador" item-value="id" item-text="name" label="Cargo" :style="{ maxWidth: '40%' }"
      @update:search-input= "(value) =>llenarLista('cargo',listaEdicion.cargoEvaluador,value,'idCargo','nombre',false)"
      :rules="rules.required" :hide-details="false" :append-icon="computedAppendIcon"
      />
        <!--Campo para rol del lider-->
      <FilterCustomized
      v-model="crearItem.rolEvaluadorValue" 
      :readonly="!permisosHR || crearItem.listaEvaluacion.eliminado"
      :items="rolEvaluador" item-value="id" item-text="name" label="Rol" 
      :style="{ maxWidth: '20%' }"
      :rules="rules.required" 
      :hide-details="false" 
      :append-icon="computedAppendIcon"
      />
    </section>

    <!--Sección para la generación de preguntas de desempeño y potencial-->
    <section v-for="(tipo, indexTipo) in tiposGruposPreguntas" :key="indexTipo">
      <!--Nombre de acuerdo con el tipo de pregunta Desempeño o Potencial-->
      <v-card-title class="pb-0 pt-2">
        {{tipo.name}}
      </v-card-title>
      <!--Genera una card para cada grupo de preguntas y le asocia sus preguntas-->
      <v-card v-for="(grupo, index) in setGruposPreguntas" :key="index" class="mt-2">
        <section class="d-flex" v-if="grupo.pregunta.grupoPregunta.tipoPreguntaValue === tipo.value">
          <v-card :style="{ width: '20%', color: 'white'}" class="align-content-center text-center" color="primary">
            {{grupo.pregunta.grupoPregunta.descripcion}}
          </v-card>
          <div :style="{ width: '80%'}">
            <section v-for="(respuesta,index) in crearItem.respuestaPregunta.filter(r => r.pregunta.grupoPregunta.descripcion == grupo.pregunta.grupoPregunta.descripcion)" :key="index"
              :style="{ width: '100%' }" class="d-flex">
              <v-card :style="{ width: '80%' }" class="align-content-center text-justify pa-1" 
                :color="index % 2 === 0 ? '#EEEEEE' : 'white'" flat>
                {{respuesta.pregunta.descripcion}}
              </v-card>
              <v-card  class="align-content-center pt-1 pb-1" :style="{ width: '20%'}" :color="index % 2 === 0 ? '#EEEEEE' : 'white'" flat>
                <FilterCustomized v-model="respuesta.calificacion" class="ml-2" :disabled="!permisosEdicion || !permisosEvaluador"
                :items="calificacion" item-value="id" item-text="name" :rules="rules.required" :key="grupo.pregunta.idPregunta + index"
                @input="calculateResult()"/>
              </v-card>
            </section>
          </div>
        </section>
      </v-card>
      <!--Genera dos card, una para "Competencias Organizacionales" y la otra para "Competencias Individuales"-->
      <section v-if="tipo.value === 'D'">
        <v-card v-for="(tipoCompetencia,indexTipo) in tiposCompetencias" :key="indexTipo" class="mt-2">
          <section class="d-flex" v-if="crearItem.respuestaCompetencia.some(r => r.competenciaDescriptor.competencia.tipoCompetenciaValue == tipoCompetencia.value)">
            <v-card :style="{ width: '20%', color: 'white'}" class="align-content-center text-center"  color="primary">
              {{tipoCompetencia.name}}
            </v-card>
            <div :style="{ width: '80%' }">
              <section v-for="(respuesta,index) in crearItem.respuestaCompetencia.filter(r => r.competenciaDescriptor.competencia.tipoCompetenciaValue == tipoCompetencia.value)" :key="index"
              :style="{ width: '100%' }" class="d-flex">
                <v-card :style="{ width: '80%' }" class="align-content-center pa-1 text-justify" :color="index % 2 === 0 ? '#EEEEEE' : 'white'" flat>
                  {{`${respuesta.competenciaDescriptor.competencia.nombre} Nivel ${respuesta.competenciaDescriptor.nivel}: ${respuesta.competenciaDescriptor.descripcion}`}}
                </v-card>
                <v-card  class="align-content-center pt-1 pb-1" :style="{ width: '20%'}" :color="index % 2 === 0 ? '#EEEEEE' : 'white'" flat>
                  <FilterCustomized v-model="respuesta.calificacion" class="ml-2"
                  :items="calificacion" item-value="id" item-text="name" :disabled="!permisosEdicion || !permisosEvaluador"
                  @input="calculateResult()" :rules="rules.required" :key="index + tipo.value + indexTipo"/>
                </v-card>
              </section>
            </div>
          </section>
        </v-card>
      </section>
    </section>
  
  <!--Sección para digitar los aspectos positivos y por mejorar del colaborador-->
    <v-card-title class="mb-0">
    Observaciones
    </v-card-title>

    <section class="d-flex">
      <v-textarea label="Aspectos Positivos" v-model="crearItem.obsPositivos" class="mr-2 mb-0" :disabled="!permisosEdicion || !permisosEvaluador"
        compact outlined color="primary" counter="500" 
        :rules="[
          v => (v && v.length >= 50) || 'Mínimo 50 caracteres',
          v => (v && v.length <= 350) || 'Máximo 350 caracteres'
        ]">
      </v-textarea>
      <v-textarea label="Oportunidades de Mejora" v-model="crearItem.obsMejorar" :disabled="!permisosEdicion || !permisosEvaluador" 
        compact outlined color="primary" counter="500"
        :rules="[
          v => (v && v.length >= 50) || 'Mínimo 50 caracteres',
          v => (v && v.length <= 350) || 'Máximo 350 caracteres'
        ]">
      </v-textarea>
    </section>

    <v-card-title class="pt-0">
    Resultados
    </v-card-title>

    <!--Tabla para mostrar los resultados de la evaluación-->
    <div class="mb-2" :style="{ maxWidth: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}">
      <v-card :style="{ minWidth: '20rem'}">
        <v-data-table dense class="elevation" :headers="resultados.headers" :items="resultados.items" 
          hide-default-footer>
        </v-data-table> 
      </v-card>
    </div>

    <!--Resultados 9BOX-->
    <section class="d-grid" width="100%">
      <v-btn width="100%" color="primary">
        Ubicación 9Box (Cuadrante)
      </v-btn>
      <section class="d-flex">
        <v-card width="50%" class="d-grid text-center pt-2 pb-2" :color="nineBoxColor" style="font-size: large;">
          <div>{{crearItem.nineBox}}</div>
          <div>{{nineBoxNombre}}</div>
        </v-card>
        <v-card width="50%" class="text-center pt-2 pb-2" style="color:black">
          {{nineBoxDescripcion}}
        </v-card>
      </section>
    </section>

    <!--Muestra las nominaciones asociadas al colaborador-->
    <v-card-title class="pt-3">
    Nominaciones
    </v-card-title>
    
    <v-data-table :headers="tablaNominaciones.headers" :items="tablaNominaciones.items" class="elevation" hide-default-footer>
      <template v-slot:[`item.id`]="{ item }">
        <FilterCustomized v-model="crearNominaciones[item.id].cargoNominado.idCargo" :disabled="crearItem.estadoEvaluacionValue === 'T' || crearItem.estadoEvaluacionValue === 'A'"
        :items="item.lista" item-value="id" item-text="name" placeholder="Digite texto para buscar cargo"
        @update:search-input= "(value) =>llenarLista('cargo',item.lista,value,'idCargo','nombre',true)"/>   
      </template>
    </v-data-table>

    <!--Muestra los reemplazos asociadas al colaborador-->
    <v-card-title class="pt-3">
    Reemplazos
    </v-card-title>
    
    <v-data-table :headers="tablaReemplazos.headers" :items="tablaReemplazos.items" class="elevation" hide-default-footer>
      <template v-slot:[`item.id`]="{ item }">
        <FilterCustomized v-model="crearReemplazos[item.id].colaboradorReemplazo.idColaborador" :disabled="crearItem.estadoEvaluacionValue === 'T' || crearItem.estadoEvaluacionValue === 'A'"
        :items="item.lista" item-value="id" item-text="name" placeholder="Digite texto para buscar colaborador"
        @update:search-input= "(value) =>llenarLista('colaborador',item.lista,value,'idColaborador','nombre',true)"/>   
      </template>
    </v-data-table>
  </v-form>    
</template>

<script>
import { mapState } from "vuex";
import FilterCustomized from "./FilterCustomized.vue";
import { required } from "@/validators/validations";

export default {
  name:'PerformanceForm',
  components:{
    FilterCustomized,
  },
  props:{
    crearItem:{
      type:Object,
      required:true
    },
    crearNominaciones:{
      type:Array,
      required:true
    },
    crearReemplazos:{
      type:Array,
      required:true
    },
    listaEdicion:{
      type:Object,
      required:true
    },
    rolEvaluador:{
      type:Array,
      required:true
    },
    setGruposPreguntas:{
      type:Array,
      required:true
    },
    estadoEvaluacion:{
      type:Array,
      required:true
    },
    resultados:{
      type:Object,
      required:true
    },
    tablaNominaciones:{
      type:Object,
      required:true
    },
    tablaReemplazos:{
      type:Object,
      required:true
    },
    llenarLista:{
      type:Function,
      required:true
    },
    listar:{
      type:Function,
      required:true
    },
    permisosHR:{
      type:Boolean,
      required:true
    },
    permisosEvaluador:{
      type:Boolean,
      required:true
    },
    validationMessage:{
      type: String,
      required: true
    }
  },
  data(){
    return {
      tiposGruposPreguntas:[{value:'D',name:'Desempeño'},{value:'P',name:'Potencial'}],
      tiposCompetencias:[{value:"O",name:"Competencias Organizacionales"},{value:"I",name:"Competencias Individuales"}],
      calificacion:[
          {name:'😔 Deficiente', id:1},
          {name:'🙁 Bajo', id:2},
          {name:'😐 Básico', id:3},
          {name:'😁 Alto', id:4},
          {name:'⭐ Superior', id:5},
        ],
      userRoles : {},
      rules: {
        required: [required]
      },
      bloqueoGeneral:false,
    }
  },
  computed: {
    ...mapState(["auth", "notify", "busy", "enterprise"]),
    formValid() {
      const { nombre } = this.crearItem;
      return nombre !== "";
    },
    permisosEdicion(){
      return !(this.crearItem.estadoEvaluacionValue === 'T' || this.crearItem.estadoEvaluacionValue === 'A');
    },
    nineBoxColor(){
      return this.nineBoxDetalle(this.crearItem.nineBox).color
    },
    nineBoxDescripcion(){
      return this.nineBoxDetalle(this.crearItem.nineBox).descripcion
    },
    nineBoxNombre(){
      return this.nineBoxDetalle(this.crearItem.nineBox).nombre
    },
    computedAppendIcon() {
      return (!this.permisosHR || this.crearItem.listaEvaluacion.eliminado) ? 'undefined' : '$dropdown';
    }
  },
  methods:{
    //Método para calcular los resultados de evaluación de desempeño
    calculateResult(){
      if(!this.crearItem.respuestaPregunta.some(p => p.calificacion === null) && !this.crearItem.respuestaCompetencia.some(c => c.calificacion === null)){
        const calificacionesPreguntasDesempeño = this.crearItem.respuestaPregunta.filter(c => c.pregunta.grupoPregunta.tipoPreguntaValue === "D").map(c => c.calificacion);
        const calificacionesPreguntasPotencial = this.crearItem.respuestaPregunta.filter(c => c.pregunta.grupoPregunta.tipoPreguntaValue === "P").map(c => c.calificacion);
        const calificacionesCompetencias = this.crearItem.respuestaCompetencia.map( c => c.calificacion);
        const calificacionesDesempeno = [...calificacionesPreguntasDesempeño,...calificacionesCompetencias];
        const calificacionesTotal = [...calificacionesDesempeno, ...calificacionesPreguntasPotencial];

        const promedioDesempeno = calificacionesDesempeno.reduce((total, num) => total + Number(num), 0)/calificacionesDesempeno.length;
        const promedioPotencial = calificacionesPreguntasPotencial.reduce((total, num) => total + Number(num), 0)/calificacionesPreguntasPotencial.length;
        const promedioTotal = calificacionesTotal.reduce((total, num) => total + Number(num), 0)/calificacionesTotal.length;
        
        this.crearItem.resultadoGeneral = promedioTotal;
        this.crearItem.desempeno = this.redondeo(promedioDesempeno);
        this.crearItem.potencial = this.redondeo(promedioPotencial);
        this.crearItem.nineBox = this.calculoNineBox(this.crearItem.desempeno, this.crearItem.potencial);
        this.resultados.items[0].calificacion = this.crearItem.desempeno == 1 ? 'Bajo' : this.crearItem.desempeno == 2 ? 'Medio' : this.crearItem.desempeno == 3 ? 'Alto' : 'NA';
        this.resultados.items[1].calificacion = this.crearItem.potencial == 1 ? 'Bajo' : this.crearItem.potencial == 2 ? 'Medio' : this.crearItem.potencial == 3 ? 'Alto' : 'NA';
        this.resultados.items[2].calificacion = promedioTotal.toFixed(2);
      }
      this.crearItem.obsMejorar = this.crearItem.obsMejorar? this.crearItem.obsMejorar + ' ':' ';
      this.crearItem.obsMejorar = this.crearItem.obsMejorar? this.crearItem.obsMejorar.trim():'';
    },
    //Función que calcula el cuadrante 9Box dado la evaluación de desempeño
    calculoNineBox(desempeno,potencial){
      const combinedArgs = `${desempeno}-${potencial}`;

      switch (combinedArgs) {
        case '1-1':
          return 1;
        case '1-2':
          return 3;
          case '1-3':
          return 6;
          case '2-1':
          return 2;
          case '2-2':
          return 5;
          case '2-3':
          return 8;
          case '3-1':
          return 4;
          case '3-2':
          return 7;
          case '3-3':
          return 9;
      }

    },
    //Función para determinar el cuadrante 9Bos en letras y su descripción
    nineBoxDetalle(nineBox){
      switch(nineBox){
        case 1:
          return {nombre:'Caso Crítico / Lower Performer',
                  descripcion:'No genera resultados como se esperaba y se le dificulta adaptarse efectivamente a nuevas situaciones. El que ocupa este cuadrante requiere de acciones de intervención en su desempeño.',
                  color: 'red'
                  };
        case 2:
          return {nombre:'Colaborador Sólido / Solid Professional',
                  descripcion:'Cumple con sus resultados y, en ocasiones, excede las expectativas. Conoce bien su trabajo actual. Tiene dificultades para adaptarse efectivamente a nuevas situaciones. Tiene intereses profesionales focalizados.',
                  color:'#366092'
                  };
        case 3:
          return {nombre:'Caso en Seguimiento / Inconsistent Performer',
                  descripcion:'Tiene potencial para hacer más pero no lo ha demostrado completamente. No cumple con los estándares de desempeño. Puede ser una nueva incorporación o estar en una función o posición equivocada.',
                  color:'red'
                  };
        case 4:
          return {nombre:'Colaborador Sobresaliente / High Professional',
                  descripcion:'Consistentemente produce resultados excepcionales, aunque no siempre se adapta fácilmente a nuevas situaciones fuera de su área de expertise. Puede ser candidato para promociones o liderar su área.',
                  color:'#92D050'
                  };
        case 5:
          return {nombre:'Colaborador Clave / Key Performer',
                  descripcion:'Cumple con las expectativas de su rol y mejora sus habilidades para el futuro a corto plazo. Asume cómodamente nuevos retos y tiene buen desempeño. Puede ser candidato para un movimiento a un nivel superior o lateral.',
                  color:'#0000FF'
                  };
        case 6:
          return {nombre:'Colaborador Promesa / Diamond In The Rough',
                  descripcion:'Tiene un alto potencial, aunque aun no ha demostrado lo que es capaz de hacer, ya sea porque no ha tenido las oportunidades o ha sido inconsistente en el pasado. La mayoría está de acuerdo en que podría hacer aportaciones significativas en la organización en un futuro. Puede estar en un puesto de trabajo equivocado.',
                  color:'#FFC000'
                  };
        case 7:
          return {nombre:'Colaborador de Alto Impacto / High Professional Plus',
                  descripcion:'Consistentemente produce resultados excepcionales en muchas áreas, negocios, geografías, funciones. Se adapta a nuevas situaciones y aprende de nuevas áreas. Puede ser candidato para promociones o liderar diversas áreas.',
                  color:'#92D050'
                  };
        case 8:
          return {nombre:'Liderazgo Emergente / Future Star',
                  descripcion:'Cumple o excede las expectativas y tiene la capacidad de asumir fácilmente nuevos desafíos. Se adapta rápidamente a nuevas asignaciones. Tiene el potencial para cambiar de área.',
                  color:'#92D050'
                  };
        case 9:
          return {nombre:'Liderazgo Consistente / Consistent Star',
                  descripcion:'Normalmente son escasos y muy demandados. Se desempeña bien en casi todas las áreas en las que se involucra. Aprende rápido. Optimiza tiempos y recursos. Transfiere el aprendizaje de un área a otra. Ingenioso. Está preparado para asignaciones de mayor responsabilidad',
                  color:'#008000'
                  };
        default:
          return {nombre:'',
                  descripcion:'(Complete evaluación para ver detalles)',
                  color:'#FFFFFF'};
      }
    },
    //Redondeo para desempeño y potencial en escala de metodología NineBox
    redondeo(value){
      return value > 4 ? 3 : value >= 3 ? 2 : 1;
    }
  },
  created() {
    this.userRoles = this.auth.roles;
  },
}
</script>
<style scoped>
.contenido {
  padding: 1rem;
  width: 100%;
  height: 85vh;
}

.encabezado {
  background-color: #1867c0;
  color: white;
}

::v-deep .elevation div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .elevation div table thead tr th span {
  font-weight: bold;
  color: black !important;
}
</style>
